import React from 'react';
import { useState,useRef, useEffect } from "react";
import axios from "axios";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import CakesHeader from './CakesHeader';
import CakesBody from './CakesBody';
import { Link } from "react-router-dom";
import {IoIosArrowDropleftCircle  } from 'react-icons/io';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';
import excel from "./assets/excel.png";
import DetailedCakeInventory from './DetailedCakeInventory';
import MultipleCakesInventory from './MultipleCakesInventory';
import CakesSideBar from './CakesSideBar';
import SingleCakesInventorySheet from './SingleCakesInventorySheet';

const Places = [
    "Ilford lane",
    "Leyton",
    "Luton",
    "Melton",
    "Norwood",
    "Rayners Lane",
    "Ruislip Manor",
    "South Woodford",
    "Walthamstow",
    "Tonbridge",
    "All"
  ];
const tableHeaders=['CAKES', 'Mini choco vanilla', 'Mini fruit vanilla','Mini ganache ', 'Mini Oreo ', 'Mini Raffaello ','Mini Biscoff','Pink vanilla','Blue vanilla','Black forest ','Chocolate heaven','Choco vanilla','Ferrero Rocher','Mango cake','Nutella cake','Pistachio cake','Fruit paradise','Red Velvet ' ,'Strawberry cake','White forest cake ' ,'Lotus Biscoff cake','Oreo cake','Only berries cake','Fruitful','Ch overload',
                     'SLICES','Mango','Lotus Biscoff','Strawberry','Pistachio','Oreo','Black forest','Ferrero Rocher','Truffle treat','Red velvet','Carrot','Lemon berry ','Tangy truffle','Rainbow',
                    'CUPCAKES', 'Mango','Lotus Biscoff','Rainbow','Blondie','Pink vanilla','Blue vanilla','Pistachio','Oreo','Ferrero','Black forest','Brownie','Red velvet','Carrot',
                    'LOAF CAKES', 'Banana ', 'Marble','Madeira', 'BROWNIES', 'Chocolate','Oreo','Lotus Biscoff','White chocolate', 
                    'BISCUITS', 'Almond', 'Tea', 'HOT EATS', 'Spicy veg', 'Veg samosa','Paneer','Potato','Gobi roll','Cheese & onion','Cheese & Jalapenos','Spicy chicken','Creamy chicken','Spicy lamb',
                    'DRINKS', 'Red bull', 'Coke 330 ml','Diet coke 330 ml','Coke zero 330 ml','Pepsi max 330 ml','Ribena','Coconut water','Lucozade','Fanta 330 ml','7up 330 ml','Evian water','Starbucks frappuccino','Tropical orange juice','Tropical apple juice' ,'Tropical mixed fruit juice',
                    'COOKIES', 'Red velvet cookies', 'Chocolate chip cookies','Cranberry cookies','Triple chocolate chip cookies','CHOCOLATES', 'Dairy milk','Kitkat','Kinder beuno white','Kinder beuno dark','Maltesers','Ferrero rocher','Oreo biscuits','Lotus Biscoff Biscuits',
                    'CAKE TOPPINGS', 'Chocolate flakes','Cherries','Barbara décor/filgranes','Zebra sticks','White sugar pearls','Gold sugar pearls','Pink sugar pearls','Silver sugar pearls','Rainbow sprinkles','Chocolate vermicelli','Chocolate curls','Marble curls','White chocolate curls','Strawberry curls','Dark chocolate shavings','Margarine','Cocoa powder',
                    'MILKSHAKES/SMOOTHIES', 'Strawberry glory','Top banana','Livin la vida Cocoa','Va va vanilla','Strawberry split','Berry go round','Pash n Shoot',
                    'ESSENTIALS', 'Antibacterial spray','Glass cleaner','Plastic forks','Plastic spoons','Napkins','Plates','Bags','Washing liquid','Floor cleaner','Blue rolls','Gloves','Slice wraps','Food hygiene labels','Hairnets','Toilet rolls','Baking paper','Piping bags','Order books','Bin bags','Till rolls','Card machine rolls',
                    'SPONGES','Chocolate cupcakes','Vanilla cupcakes','Red velvet cupcakes','6" eggles vanilla','6" eggless chocolate','8" eggless vanilla','8" eggless chocolate','8" vanilla with egg','8" chocolate with egg','8" red velvet','10" eggless vanilla','10" eggless chocolate','10" vanilla with egg','10" chocolate with egg','10" red velvet','12" eggless vanilla','12" eggless chocolate','12" vanilla with egg','12" chocolate with egg','14" eggless vanilla' ,'14" eggless chocolate','14" vanilla with egg','14" chocolate with egg','16" eggless vanilla','16" eggless chocolate','16" vanilla with egg','16" chocolate with egg','16X28 eggless vanilla','16X28 eggless chocolate','16X28 vanilla with egg','16X28 chocolate with egg','16X28 red velvet',
                    'BOARDS', '8" round boards','10" round boards','12" round boards','14" round boards','16" round boards','16X28 boards','10" square boards','12" square boards','14" square boards','16" square boards', ]

const CakesInventory = () => {
    const tableRef = useRef(null);
    const [data, setData] = useState([]);
  const [location, setLocation] = useState("");
  const [error, setError] = useState("");
  const [duration, setDuration] = useState("");
  const [startdate, setStartdate] = useState(new Date ());
  const today = new Date();
  const [selectedstartDate, setSelectedstartDate] = useState(new Date ());
  const [selectedendDate, setSelectedendDate] = useState(new Date ());
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const history = useNavigate();
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true';
  const DataCount = data.length === 1? true: false
  useEffect(() => {
    if(!isAuthenticated){
      history('/'); 
    }
  }, []); 
  const handlestartDateChange = (event) => {
    setSelectedstartDate(new Date(event.target.value));
  };
  const handleendDateChange = (event) => {
    setSelectedendDate(new Date(event.target.value));
  };
  const handleDateChange = (event) => {
    setStartdate(new Date(event.target.value));
  };
  const changelocation = (event) => {
    setLocation(event.target.value);
  };
  const changeduration = (event) => {
    setDuration(event.target.value);
   
    if (event.target.value === "custom") {
      setVisible1(false);
      setVisible2(true);
    }
    if (event.target.value === "select") {
      setVisible1(true);
      setVisible2(false);
    }
  };
  const fetchAllyesterdayData = async () => {
    setError('')
    const dateFormat =startdate.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    })
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/cake/getSingleDayinventory",
        {
          dateFormat: dateFormat,
          store: location,
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
      setData(response.data ? response.data : []);
      setError('');
      console.log('response.data****',response.data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData = async () => {
    setError('')
    if(duration === "custom"){
      if(selectedendDate<selectedstartDate)
    {
      setError('End date should greater than start date')
      return
    }
    if(selectedendDate.getTime() === selectedstartDate.getTime())
    {
      setError('End date should greater than start date')
      return
    }
    }
    try {
      const username1 = 'blupace24';
      const password1 = 'LoginPassword@';
      const base64Credentials = btoa(`${username1}:${password1}`);
      const headers = {
        'Authorization': `Basic ${base64Credentials}`,
        
      };
      const response = await axios.post(
        "http://my-first-app-env.eba-3f4bm6tr.eu-west-2.elasticbeanstalk.com/api/cake/getCustomDurationInventory",
        {
          startdate: selectedstartDate.toISOString(),
          enddate: selectedendDate.toISOString(),
          store: location,
        },
        {
          headers:headers
        }
      );
      if(response.data.length === 0){
        setData([]);
        setError('There is no data for selected location and selected duration');
        return
      }
      console.log("Data received successful:", response.data);
      setData(response.data);
      setError('');
    } catch (error) {
      console.error("Error registering user:", error.message);
      setError('Something went wrong')
    }
  };
  const getData = () => {
    if(location == '')
    {
      setError('Please select Location!')
      return
    }
    if(duration==='select'){
      if(startdate>today){
        setError('Selected date is future date')
        return
      }
      fetchAllyesterdayData();
      return
    }
    
    fetchData();
    
  };

    
      return (
        <div >
          <CakesSideBar />
          
          <div  style={{ marginLeft: 150,marginTop:30 }}>
    <Link to="/cakes&bakes" >
  <div style={{display: 'flex'}}>
  <button style={{ backgroundColor:'white'}}>
      &#8592; Back
    </button>
    </div>
    </Link>
          <p style={{textAlign:'left', marginTop:20,fontSize:36, color: '#244999'}} >Cakes & Bakes Inventory</p>
          
             <p style={{color:'red', alignItems:'center'}}>{error}</p>
       
       <div className="row">
         <div className="col-md-3" style={{marginTop:10}}>
           <select
             className="form-control"
             value={location}
             onChange={changelocation}
             style={{color: '#121314',fontSize:20, height:40}}
           >
             <option>Location</option>
             {Places.map((place) => (
               <option value={place}>{place}</option>
             ))}
           </select>
         </div>
         <div className="col-md-3" style={{marginTop:10}}>
          <select
            className="form-control"
            value={duration}
            onChange={changeduration}
            style={{color: '#121314',fontSize:20, height:40}}
          >
            <option>Duration</option>
            <option value="select">Select date</option>
            <option value="custom">Custom Duration</option>
          </select>
        </div>
        
         <div className="col-md-3" style={{marginTop:10}}>
           <button onClick={getData} style={{height:40,borderRadius:5,borderWidth:0, fontSize:20, color:'#FFFFFF', backgroundColor:'#244999', borderBlockColor:'#244999'}}>Get Inventory Data</button>
         </div>
         {/* <div  className="col-md-3" style={{marginTop:10}}>
                <DownloadTableExcel
                  filename={'Cakes & Bakes Inventory'}
                  sheet="Inventory"
                  currentTableRef={tableRef.current}
                >

                  <button style={{fontSize:20,height:40, color:'#FFFFFF',backgroundColor:'#048E5B',borderWidth:0,borderRadius:5}} ><img src={excel} alt="Logo" style={{width:18, height:20}}/> Export excel </button>

                </DownloadTableExcel>
              </div> */}

         
       </div>
       {visible1 &&  <div className="col-md-4" style={{marginTop:20}}>
       <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5}}>
            <div style={{height:40,alignItems:'center', justifyContent:'center', padding:8}}>
            <label style={{textAlign:'center',color:'#6E6E6E'}}>Select Date :</label>
            </div>
            <input
              type="date"
              value={startdate.toISOString().split('T')[0]}
              onChange={handleDateChange}
              style={{ marginLeft: 5,border: 'none' , height:30,color:'#121314', fontSize:20,marginBottom:5,marginTop:5,}}
            />
            </div>
          </div>}
       {visible2 && <div className="row" style={{ marginTop: 50 , alignItems:'center', justifyContent:'space-evenly'}}>
              <div className="col-md-3" style={{marginTop:10}}>
              <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5, }}>
            <div style={{height:40,alignItems:'center', justifyContent:'center', padding:8}}>
            <label style={{textAlign:'center',color:'#6E6E6E'}}>Start Date :</label>
            </div>
            <input
              type="date"
              value={selectedstartDate.toISOString().split('T')[0]}
              onChange={handlestartDateChange}
              style={{ marginBottom:5,border: 'none' , height:30,color:'#121314', fontSize:20, marginTop:5}}
            />
            </div>
          </div>
          <div className="col-md-3" style={{marginTop:10}}>
          <div className="row" style={{border: '1px solid #DCDCDC',borderRadius:5, }}>
            <div style={{height:40,alignItems:'center', justifyContent:'center', padding:8}}>
            <label>End Date :</label>
            </div>
            <input
              type="date"
              value={selectedendDate.toISOString().split('T')[0]}
              onChange={handleendDateChange}
              style={{ marginBottom:5,border: 'none' , height:30,color:'#121314', fontSize:20, marginTop:5}}
            />
            </div>
          </div>
      </div>}
            {/* <div style={{marginTop:25}}>
    <table class="table" ref={tableRef}>
    <CakesHeader headers={tableHeaders} />
    <CakesBody contents={data}/>
    </table>
    </div> */}
    {/* {DataCount && <div style={{marginTop:30}}>
            <DetailedCakeInventory data={data}/>
            </div>}
            { !DataCount && <div style={{marginTop:30}}>
            <MultipleCakesInventory data={data}/>
            </div>} */}
            <div style={{marginTop:30}}>
            <SingleCakesInventorySheet data={data}/>
            </div>
    </div>
        </div>
      );
};

export default CakesInventory;
